.container{
  background-color: rgb(var(--clr-neutral-900));
  width: 100%;
  height: 100vh;
  color: rgba(var(--clr-neutral-50), 0.8);
}
.startMessage{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap:2rem;
  align-items: center;
  justify-content: center;
}

.carouselContainer{
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  font-size: clamp(28px, 2.2vw, 81px);
}
.page{
  top: 0;
  left:0;
  color: rgb(var(--clr-neutral-900));
  padding: 1rem;
  background-color: rgb(var(--clr-neutral-50));
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: transform 1.5s ease-in-out;
  cursor: none;
}

.page[data-current=true]{
  z-index: 10;
}
.page[data-previous=true]{
  z-index: -1;
  transform:translateX(-100%);
}
.page[data-next=true]{
  z-index: -1;
  transform:translateX(100%);
}

.pageHeader{
  display: flex;
  margin-bottom: 0.25rem;
  align-items: center;
  min-height: 4rem;
}

.pageTitle{
  width: 100%;
}
.pageTitle h3{
  margin: auto;
}

.pageHeader>div{
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.pageHeader img{
  height: 48px;
}
@media screen and (max-width: 1680px) {
  .page {
    padding: 0.75rem;
  }

  .pageHeader img {
    height: 40px;
  }
}
@media screen and (max-width: 1440px) {
  .page {
    padding: 0.5rem;
  }

  .pageHeader img {
    height: 36px;
  }
}