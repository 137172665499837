.success {
  --toast-color: var(--clr-success);
  --toast-background: #e6fffa;
}

.error {
  --toast-color: var(--clr-error);
  --toast-background: #fddedd;
}

.info {
  --toast-color: rgb(var(--clr-info));
  --toast-background: #e6ffff;
}

.container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .container{
    padding: 8px !important;
  }
}

.toast {
  position: relative;
  width: 360px;
  border-radius: 10px;
  padding: 16px 30px 16px 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  box-shadow: var(--shadow-lg);
  background-color: var(--toast-background);
  color: var(--toast-color);
}
.withDescription {
  align-items: flex-start !important;
}

.withDescription > svg {
  margin: 4px 12px 0 0;
}
.toast + div {
  margin-top: 8px;
}
.toast > svg {
  margin: 0 12px 0 0;
}
.toastMessage {
  flex: 1;
}
.toastMessage p {
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast button {
  cursor: pointer;
  position: absolute;
  top: 19px;
  right: 12px;
  border: 0;
  background: transparent;
  display: flex;
  color: inherit;
}
.toast button:hover {
  opacity: var(--opacity-mid);
}
