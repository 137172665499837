#management-login-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 600px) {
  #management-login-form {
    max-width: 500px;
    padding: 3rem !important;
  }
}

#welcome {
  display: flex;
  flex-direction: column;
  gap:1rem;
  align-items: center;
}
#management-login-form {
  width: 100%;
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(var(--clr-neutral-900));
  background-color: rgb(var(--clr-neutral-50));
}
#management-login-form img{
  width: 220px;
  margin-bottom: 3rem;
}
#management-login-form form {
  width: 100%;
  margin: 2rem 0;
}
#management-login-form form button {
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
}
@media screen and (min-width: 600px) {
  #management-login-image {
    width: 100% !important;
  }
}
#management-login-image {
  width: 0;
}
#management-login-image img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
