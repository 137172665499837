.container h2 {
  height: 2rem;
}

.container {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 1;
  padding: 1rem;
height: 440px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}
.dataContainer {
  height: 100%;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(var(--clr-neutral-900));
}
.header{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}
.header>svg {
  cursor: pointer;
  stroke: rgba(var(--clr-accent));
  fill: rgba(var(--clr-accent));
}
.header>svg:hover{
  fill: rgba(var(--clr-accent), 0.6);
}

.dataLabel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.25rem;
}
.data strong{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.dataValue {
  letter-spacing: 0.08rem;
}
@media screen and (max-width: 1200px) {
  .container {
    grid-column: span 2;
    grid-row: 3;
    height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .container {
    grid-column: span 2;
    grid-row: 5;
    height: 400px;
  }
}
