.label {
  line-height: 2.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label[data-inverted] {
  color: rgb(var(--clr-neutral-50));
}
