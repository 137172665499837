.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.body {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 500px;
  padding: 3rem 1.5rem;
  color: rgb(var(--clr-neutral-900));
  background-color: rgb(var(--clr-neutral-50));
}
.body>div {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
}
.body form {
  display: flex;
  width:100%;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xm);
  margin: 0;
}

.background {
  width: 100%;
}

.background img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .body {
    min-width: 100%;
  }

  .body form {
    gap: var(--spacing-xl);
    margin: var(--spacing-xl) 0;
  }

  .background {
    visibility: hidden;
  }
}
