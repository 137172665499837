.actionsColumn svg {
  cursor: pointer;
  width: 22px;
  height: 22px;
}
.actionsColumn a {
  display: flex;
}
.coilsList {
  border-radius: var(--rounded);
  padding: 0 0 0.625rem 0;
  margin-top: 1rem;
  border-color: #eee;
  border-width: 1px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fff;
}
.row:not(:last-child) {
  border-bottom: 1px solid rgb(var(--clr-neutral-900));
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
}
.header {
  padding: var(--space-sm) 1.25rem;
  text-align: left;
  display: flex;
  color: rgb(var(--clr-neutral-50));
  background-color: rgb(var(--clr-neutral-900));
}
.row {
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 1rem 1.25rem;
  line-height: 2rem;
  margin: 0;
  border-radius: 0;
  align-items: center;
}
.disabledRow {
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 1rem 1.25rem;
  line-height: 2rem;
  margin: 0;
  border-radius: 0;
  align-items: center;
  background-color: rgb(var(--clr-neutral-50));
  color: rgba(var(--clr-neutral-900), 'aa');
  cursor: not-allowed;

}
.row svg:hover {
  opacity: var(--opacity-high);
}
.column{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nameColumn {
  padding-left: 0rem;
  width: 45%;
}
.codeColumn {
  width: 20%;
}
.weightColumn {
  width: 10%;
}
.estimateColumn {
  width: 10%;
}
.actionsColumn {
  width: 15%;
  display: flex;
  gap: 0.75rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  vertical-align: baseline;
}
.actionsColumn>div{
  display: flex;
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 3rem;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .buttons {
    flex-direction: column;
  }
  .nameColumn {
    padding-left: 0rem;
    width: 30%;
  }
  .codeColumn {
    width: 20%;
  }
  .weightColumn {
    width: 15%;
  }
  .estimateColumn {
    width: 20%;
  }
}
