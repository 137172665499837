.container {
  padding: 1.5rem 3rem;
}

.container h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
