.modal {
  max-height: 80%;
  max-width: 100%;
  background-color: white;
  border-radius: var(--rounded);
  z-index: 1;
}

.backsplash {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #00000022;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  isolation: isolate;
  position: absolute;
}
.modal {
  max-width: 90%;
}
