#manager-profile-info {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#manager-profile-info a {
  transition: 50ms 0s ease-in;
}
#manager-profile-info a:hover {
  opacity: var(--opacity-low);
}
#manager-profile-info a img,
#manager-profile-info a svg {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgb(var(--clr-neutral-50));
  color: rgb(var(--clr-neutral-900));
  cursor: pointer;
}
#manager-profile-info div {
  margin-top: 1rem;
  color: rgb(var(--clr-neutral-50));
  display: flex;
  align-items: flex-end;
}

#manager-profile-info div > svg {
  color: rgb(var(--clr-neutral-50));
  margin-left: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  transition: 50ms 0s ease-in;
}
#manager-profile-info div > svg:hover {
  opacity: var(--opacity-low);
}

#manager-profile-info .profile-name {
  margin-top: 1rem;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

#management-menu-list {
  margin: 1rem 0;
}

#management-menu-list li {
  vertical-align: middle;
  position: relative;
  isolation: isolate;
  padding: 0.5rem 0;
}
#management-menu-list a.active {
  color: rgb(var(--clr-accent));
}

#management-menu-list li div {
  width: 100%;
  padding-left: 0.25rem;
}

#management-menu-list li:before {
  content: '';
  position: absolute;
  transition: all 200ms 0s ease-out;
  transform: scale(0, 1);
  top: 0;
  transform-origin: left;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgb(var(--clr-neutral-50));
}

#management-menu-list li:hover {
  color: rgb(var(--clr-neutral-900));
}

#management-menu-list li:hover:before {
  transform: scale(1, 1);
}

#management-menu-list hr {
  color: rgba(rgb(var(--clr-neutral-50)), 0.2);
}
