.container {
    margin: 0 auto;
    padding: 2rem 1.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  .tableContainer {
    background-color: transparent;
    border-radius: var(--rounded);
    padding: 0.5rem;
    margin: 0.5rem 0;
    margin-top: 1rem;
    border-collapse: collapse;
    width: 100%;
    padding-top: 0.5em;
    flex: 0 0 auto;
    max-height: 200px;
    font-size: clamp(12px, 1vw, 18px);
  }
  
  .tableSection {
    max-height: 44vh;
    overflow-y: auto;
    margin-bottom: 1rem;
  }
  table,
  th,
  td {
    border: 1px solid rgba(var(--clr-neutral-900), 0.1);
  }
  .tableContainer td {
    padding: 0.25em 0.5em;
  }
  .tableContainer th {
    text-align: start;
    padding: 0.25em 0.5em;
    font-weight: 600 !important;
  }
  .tableContainer tr {
    line-height: 1.25em;
  }
  .chartSection {
    flex-grow: 1;
    max-height: 420px;
    overflow: auto;
  }
  