.container h2 {
  height: 2rem;
}

.container {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 1;
  padding: 1rem;
  height: 400px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}
.dataContainer {
  height: 100%;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(var(--clr-neutral-900));
}

.data {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.data strong {
  letter-spacing: 0.08rem;
  text-align: right;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .container {
    grid-column: span 2;
    grid-row: 4;
  height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    grid-column: span 2;
    grid-row: 7;
    height: 400px;
  }
}

