.title{
  position: relative;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
.titleGroup{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
.title svg {
  cursor: pointer;
}

.titleGroup>svg {
  stroke: rgba(var(--clr-accent));
  fill: rgba(var(--clr-accent));
}
.titleGroup>svg:hover{
  fill: rgba(var(--clr-accent), 0.6);
}

@media (max-width: 768px) {
  .title {
    flex-direction: column;
    align-items: flex-start;
  }
  .titleGroup {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }
}