* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  text-decoration: none;
  list-style: none;
  font-family: 'Tahoma','Verdana', sans-serif;
  outline: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

a {
  color: inherit;
}
h1 {
  font-weight: 700;
  padding: 0 4em;
  font-size: 1.35em;
  white-space: nowrap;
}
h2{
  font-size: 1.35em;
  font-weight: 400;
}
h3{
  font-size: 1.175em;
  font-weight: 700;
}
h4{
  font-size: 1.175em;
  font-weight: 400;
}

strong{
  font-weight: 600;
  letter-spacing: 0.04em;
}
small {
  font-size: 0.8em;
  line-height: 0.9em;
  color: rgba(var(--clr-neutral-900), 0.85);
}
:root {
  --clr-blue: 32, 164, 243;
  --clr-neutral-50: 240, 245, 248;
  --secondary-color: 63, 77, 102;
  --clr-neutral-900: 32, 36, 41;
  --clr-accent: 31, 73, 125;
  --clr-success: 39, 220, 148;
  --clr-warning: 225, 117, 34;
  --clr-error: 236, 89, 89;
  --clr-info: 49, 114, 183;
  --rounded-sm: 0.175rem;
  --rounded: 0.25rem;
  --rounded-md: 0.5rem;
  --space: 1rem;
  --space-sm: 0.5rem;
  --space-xm: 0.25rem;
  --opacity-low: 0.8;
  --opacity-md: 0.6;
  --opacity-high: 0.3;
  --opacity-full: 0;
  --opacity-no: 1;
  --shadow-sm: 0px 02px 6px 0px rgba(0, 0, 0, 0.08);
  --shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.1);
  --shadow-lg: 2px 2px 8px rgba(0, 0, 0, 0.2);
  --spacing-xxm: 0.25rem;
  --spacing-xm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --body-width: 1750px;
  font-size: clamp(14px,1.3vw,18px);
  color: rgb(var(--clr-neutral-900));
}

.text-sm {
  font-size: 0.875rem;
}
.text-warning {
  color: var(--clr-warning);
}
.text-success {
  color: rgb(var(--clr-accent));
}
.text-sm {
  font-size: 0.875rem;
}
.text-lg {
  font-size: 1.25rem;
}
