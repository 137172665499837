.container {
  display: flex;
  flex-direction: column;
  min-width: 10rem;
}
.container:hover .selectOutline {
  border-color: rgb(var(--clr-accent));
}
.containerGrow {
  width: 100%;
}

.labelInverted {
  color: rgb(var(--clr-neutral-50));
}
.selectOutline {
  background-color: white;
  border: 1px solid rgb(var(--clr-neutral-900));
  border-radius: var(--rounded);
  overflow: hidden;
  padding: 0.25rem;
}
.select {
  color: rgb(var(--clr-neutral-900));
  -webkit-appearance: none;
  appearance: none;
  border: none;
  line-height: 1.5rem;
  width: 100%;
}
