.container {
  background: rgba(var(--clr-neutral-900), 0.8);
  color: rgba(var(--clr-neutral-50));
  border-radius: var(--rounded);
  padding: 0.25rem 0.5rem;
  box-shadow: var(--shadow);
}
.arrow {
  fill: rgba(var(--clr-neutral-900), 0.8);
}
