.userForm {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5rem;
}
.userForm h1 {
  width: 100%;
  text-align: center;
  color: rgb(var(--clr-neutral-900));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 1rem 0 1.5rem 0;
}
.userForm button {
  margin: 1.5rem 0 0.5rem 0;
}

.controlButtons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

@media screen and (min-width: 600px) {
  .userForm {
    padding: 1rem 10rem;
  }
  .userForm h1 {
    margin: 2rem 0 4rem 0 !important;
  }
  .userForm button {
    margin: 3rem 0 2rem 0;
  }
}
