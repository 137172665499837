.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem 0;
}
.mainInputs {
  width: 100%;
  gap: 2rem;
  align-items: center;
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(4, 1fr) ;
  flex-direction: row;
}
.container h1 {
  width: 100%;
  text-align: center;
  color: rgb(var(--clr-neutral-900));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 1rem 0 1.5rem 0;
}
.mainInputs input[type='file'] {
  visibility: hidden;
}
.mainInputs .productIcon{
  grid-column: 1;
  grid-row: 1 / 3;

}
.mainInputs img {
  border-radius: var(--rounded);
}
.mainInputs .name {
  grid-column: 2 / 5;
}
.mainInputs .codeText {
  grid-column: 2 / 2;
  grid-row: 2;
}

.mainInputs .barCode {
  grid-column: 3 / 5;
  grid-row: 2;
}

.mainInputs > label {
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: var(--rounded);
}
.addImageButton {
  position: absolute;
  background-color: rgb(var(--clr-accent));
  color: white;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  right: -10px;
  bottom: -10px;
}
.formVerticalGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}
.formHorizontalGroup {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
}
.formHorizontalGroup>*{
  width: calc(50% - 1rem);
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 3rem;
  justify-content: flex-end;
}

@media screen and (max-width: 1200px){
  .container{
    padding: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .buttons {
    flex-direction: column;
  }
  .formHorizontalGroup{
    flex-direction: column;
  }
  .formHorizontalGroup>*{
    width: 100%;
  }
}
