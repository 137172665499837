@media screen and (min-width: 600px) {
  #client-form {
    padding: 0 2rem !important;
  }
}
#client-form {
  width: 100%;
  gap: 1rem;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 0.125rem;
  display: flex;
  flex-direction: column;
  color: rgb(var(--clr-neutral-900));
}

#client-form-identity {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#root-user-separator {
  align-items: center;
}
#verified {
  display: inline-block;
  margin: 0.25rem;
  color: rgb(var(--clr-accent));
}
#non-verified {
  display: inline-block;
  margin: 0 0.25rem;
  color: var(--clr-warning);
  align-self: center;
}

#client-form-identity {
  gap: 1rem;
}
#client-form-identity > * {
  flex-grow: 1;
}

#client-form button {
  margin-top: 1rem;
  color: rgb(var(--clr-neutral-50));
  align-self: flex-end;
}

#client-form-root-user {
  padding: 1.5rem 0;
}
#client-form-root-user .client-status {
  padding-left: 1rem;
}
#client-form-root-user > div {
  padding-top: 0.75rem;
  display: flex;
  justify-content: space-between;
}
#client-form-root-user > div > * {
  flex-grow: 1;
}
#client-form-root-user > div > *:first-child {
  margin-right: 0.5rem;
}
#client-form-root-user > div > *:last-child {
  margin-left: 0.5rem;
}

#subscription-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 600px) {
  #subscription-price {
    flex-direction: row;
  }
}
@media screen and (min-width: 600px) {
  #price-summary {
    text-align: end;
  }
}
#price-summary {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  display: inline-block;
}

