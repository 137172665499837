.chartContainer h2 {
  height: 2rem;
}
.chartContainer {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 2;
  padding: 1rem;
height: 440px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}

.header{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}
.header>svg {
  cursor: pointer;
  stroke: rgba(var(--clr-accent));
  fill: rgba(var(--clr-accent));
}
.header>svg:hover{
  fill: rgba(var(--clr-accent), 0.6);
}

.xaxisLegend{
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
}
.xaxisLegendItem{
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded);
  background-color: rgba(var(--clr-neutral-900),0.05 );
  border: 1px solid rgba(var(--clr-neutral-900), 0.3)
}
@media screen and (max-width: 1200px) {
  .chartContainer {
    grid-column: span 4;
    height: 440px !important;
  }
}
@media screen and (max-width: 767px) {
  .chartContainer {
    grid-column: span 2;
    height: 440px !important;
  }
  .xaxisLegend{
    gap: 0.125rem;
  }
  .xaxisLegendItem{
    padding: 0.25rem;
  }
}
