.chartContainer h2 {
  height: 2rem;
}
.chartContainer {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 1;
  padding: 1rem;
height: 400px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .chartContainer {
    grid-column: span 2;
  height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .chartContainer {
    grid-row:3;
  }
}

.chartTitle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.pagination {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
}

button {
  padding: 0.325rem 0.475rem;
  border-radius: var(--rounded);
  cursor: pointer;
}

button:disabled,
button[disabled]{
  cursor: default;
  background-color: #cccccc;
  color: #777777;
}