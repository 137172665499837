.container {
  width: 100%;
  overflow: auto;
}

.body {
  width: 100%;
  height: calc(100vh - 3.5rem);
  max-width: var(--body-width);
  padding: 0.5rem 2rem;
  margin: 0 auto;
  user-select: none;
}
.bodyGrid {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
  margin-bottom: 3rem;
}

.mobileContainer {
  display: none;
}

.desktopContainer {
  display: grid;
}

@media screen and (max-width: 1200px) {
  .mobileContainer {
    display: grid;
  }

  .desktopContainer {
    display: none;
  }
}


@media screen and (max-width: 767px) {
  .desktopContainer {
    display: none !important;
  }
  .bodyGrid {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0.25rem;
  }
  .body{
    padding: 0.5rem;
  }
}

@media screen and (max-width: 1600px){
  .body{
    height: calc(100vh - 3rem);
  }
}