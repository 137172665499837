
.container{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
  gap: 3rem;
}
@media screen and (min-width: 767px) {
  .pic{
    flex-direction: row;
  }
}
.footer{
  align-self: flex-end;
}
.logoArea {
  border:2px solid rgba(var(--clr-neutral-900));
  background-color: rgba(var(--clr-neutral-900),0.1);
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
}
.pic{
  cursor: pointer;
  margin:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.pic div{
  width: 100%;
  min-width: 300px;
}
.pic input {
  visibility: hidden;
}

.pic img {
  margin:auto;
  width: 250px;
  height: 80px;
  object-fit: scale-down;
}
.pic svg {
  width: 250px;
  height: 80px;
  object-fit: cover;
}