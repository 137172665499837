.container {
  background-color: #fff;
  padding: 3rem 5rem;
  border-radius: var(--rounded);
}

.container h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2rem;
}
.container button {
  margin-top: 2rem;
}

.footer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: flex-end;
}
