.container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem 0;
  gap: 1rem;
}
.formHorizontalGroup {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 3rem;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .buttons {
    flex-direction: column;
  }
}
