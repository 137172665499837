.chartContainer h2 {
  height: 2rem;
}
.chartContainer {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 1;
  padding: 1rem;
height: 400px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}
.donut {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .chartContainer {
    grid-column: span 2;
    grid-row: 1;
    height: 400px;
  }
}
