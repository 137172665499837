.actionsColumn svg {
  cursor: pointer;
  width: 22px;
  height: 22px;
}
.actionsColumn a {
  display: flex;
}
.productsList {
  background-color: #fff;
  border-radius: var(--rounded);
  padding: 0 0 0.625rem 0;
  margin-top: 1rem;
  border-color: #eee;
  border-width: 1px;
  overflow: hidden;
  box-sizing: border-box;
}
.row:not(:last-child) {
  border-bottom: 1px solid rgb(var(--clr-neutral-900));
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
}
.header {
  padding: var(--space-sm) 1.25rem;
  text-align: left;
  display: flex;
  color: rgb(var(--clr-neutral-50));
  background-color: rgb(var(--clr-neutral-900));
}
.row {
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 1rem 1.25rem;
  line-height: 2rem;
  margin: 0;
  border-radius: 0;
  align-items: center;
}
.row svg:hover {
  opacity: var(--opacity-high);
}
.imageColumn {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nameColumn {
  padding-left: 0rem;
  width: 40%;
}
.infoColumn {
  width: 30%;
}
.actionsColumn {
  width: 15%;
  display: flex;
  gap: 0.75rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  vertical-align: baseline;
}
.actionsColumn div {
  display: flex;
}
