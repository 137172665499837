.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem 0;
  }
  .inputs {
    width: 100%;
    gap: 2rem;
    align-items: center;
    flex-direction: row;
  }
  .container h1 {
    width: 100%;
    text-align: center;
    color: rgb(var(--clr-neutral-900));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 1rem 0 1.5rem 0;
  }
  .inputs > label {
    cursor: pointer;
    position: relative;
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: var(--rounded);
  }
  .suggestions {
    border: 1px solid #ccc;
    border-top-width: 0;
    list-style: none;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
    padding: 0;
    position: fixed;
    background-color: white;
    z-index: 10;
}
.suggestions li {
    font-size: 0.75rem;
    padding: 10px;
    cursor: pointer;
}
.suggestions li:hover {
    background-color: #ddd;
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 3rem;
  justify-content: flex-end;
}


  @media screen and (max-width: 1200px){
    .container{
      padding: 0.5rem;
    }
  }
  @media screen and (max-width: 767px) {
    .buttons {
      flex-direction: column;
    }
    .formHorizontalGroup{
      flex-direction: column;
    }
    .formHorizontalGroup>*{
      width: 100%;
    }
  }


  