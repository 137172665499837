.container {
  width: 100%;
  display: block;
  height: 2rem;
  margin: 1.75rem 0 0.75rem 0;
}

.content {
  position: relative;
  left: 1rem;
  top: 50%;
  display: inline;
  vertical-align: middle;
}
.content:before,
.content:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 10000%;
  height: 1px;
  background: rgb(var(--clr-neutral-900));
}

.content:before {
  right: 100%;
  margin-right: 15px;
}

.content:after {
  margin-left: 15px;
}
