.container h2 {
  height: 2rem;
}
.container {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 1;
  padding: 1rem;
  height: 440px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}

.header{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}
.header>svg {
  cursor: pointer;
  stroke: rgba(var(--clr-accent));
  fill: rgba(var(--clr-accent));
}
.header>svg:hover{
  fill: rgba(var(--clr-accent), 0.6);
}
.tilesContainer {
  width: 100%;
  height: calc(100% - 2rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
  justify-content: space-evenly;
}
.eventTile {
  padding: 0.125rem 0;
  width: 100%;
  gap: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
}
.eventTile:not(:last-child) {
  border-bottom: 1px solid rgba(var(--clr-neutral-900), 0.3);
}
.eventTileInfo {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  justify-content: flex-end;
  gap: 0.25rem;
}
.eventTileDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.runningEvent {
  color: rgb(var(--clr-warning));
}
.eventDuration {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-end;
}

@media screen and (max-width: 1200px) {
  .container {
    grid-column: span 2;
    grid-row: 4;
    height: 400px;
  }
  .eventTile {
    padding: 0.25rem 0;
  }
}

@media screen and (max-width: 767px) {
  .container {
    grid-column: span 2;
    grid-row: 6;
    height: 400px;
  }
  .eventTile {
    padding: 0.25rem 0;
  }
}
