.content {
  background-color: white;
  border-radius: var(--rounded);
  border: 1px solid rgba(var(--clr-neutral-900), var(--opacity-high));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: calc(100% + 48px);
  max-height: 80vh;
  overflow-y: auto;
}
.contentCheckboxItem {
  width: 100%;
  padding: 0.5rem 1.5rem;
  line-height: 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentCheckboxItem:hover {
  background-color: rgba(var(--clr-neutral-900), var(--opacity-md));
  color: white;
}
.trigger {
  cursor: pointer;
}
.contentMenuItem {
  width: 100%;
  padding: 0.5rem 1.5rem;
  line-height: 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.groupName {
  width: 100%;
  padding: 0.25rem 1rem;
  line-height: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}