.container{
  display: flex;
  flex-direction: row;
  padding: 2rem;
  padding-top: 18rem;
  justify-content: space-between;
}
.selectArea {
  display: flex;
  flex-direction: column;
  width: 60vw;
}
.reportsList {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-wrap: wrap;
  max-height: 300px;
}

@media (max-height: 800px) {
  .reportsList {
    max-height: 260px;
  }
}

.buttonsArea{
  padding: 0 4rem;
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  flex-direction: column;
}

.loadingSplash{
  position: absolute;
  top:0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingSplash svg {
  stroke: rgb(var(--clr-accent));
  fill:rgb(var(--clr-accent));
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg)
  }
}