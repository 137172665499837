
.equipmentCardsContainer{
  display: grid;
  position: relative;
  margin-top: 2rem;
  justify-content: stretch;
  width: 100%;
  height: calc(100vh - 58px - 4rem);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  --inner-gap: 1.5rem;
  font-size: clamp(22px, 2.1vw, 60px);
}
.cardTitle{
  width: 100%;
}

.cardTitle h3{
  margin: auto;
}

.equipmentCard{
  --border-width: 8px;
  --equipment-card-border-color: var(--clr-success);
  overflow: hidden;
  width: 100%;
  grid-column:  span 1;
  box-shadow: var(--shadow);
  border-radius: var(--rounded);
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: var(--border-width) solid rgba(var(--equipment-card-border-color));
  justify-content: space-between;
}

.equipmentCard[data-error=true]{
  --equipment-card-border-color: var(--clr-neutral-900);
}
.equipmentCard[data-downtime=true]{
  --equipment-card-border-color: var(--clr-error);
}
.equipmentCard[data-warning=true]{
  --equipment-card-border-color: 00 00 00 ;
}

.data{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.cardHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cardDetails{
  display: grid;
  width: calc(100% - 16px);
  grid-template-columns: 28% 24% 24% 24%;
  row-gap: 0.75em;
  column-gap: 0.125em;
}


.equipmentNameField{
  grid-row: 1;
  grid-column: 1/4;
}
.productField{
  grid-row: 2;
  grid-column:  1/4;
}

.brandField{
  grid-row: 3 ;
  grid-column:  1/4;
}

.imageField{
  margin: auto;
  width: clamp(110px, 8vw, 320px);
  height: clamp(110px, 8vw, 320px);
  object-fit: fit-content;
  grid-row: span 2;
  grid-column: 4;
}

.netWeightField{
  grid-column: 4;
  grid-row: 3;
}

.supRejField{
  grid-column: 4;
  grid-row: 4;
}
.infRejField{
  grid-column: 3;
  grid-row: 4;
}

.usefulProdField{
  grid-column: 2;
  grid-row: 4;
}

.productionField{
  grid-column: 1;
  grid-row: 4;
}
.productionData{
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.productionData h4{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media screen and (max-width: 1680px) {
}

@media screen and (max-width: 1368px) {
}
