.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
}
  
  .ordersList {
    background-color: #fff;
    border-radius: var(--rounded);
    padding: 0 0 0.625rem 0;
    margin-top: 1rem;
    border-color: #eee;
    border-width: 1px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .row:not(:last-child) {
    border-bottom: 1px solid rgb(var(--clr-neutral-900));
  }
  .header {
    padding: var(--space-sm) 1.25rem;
    display: flex;
    color: rgb(var(--clr-neutral-50));
    background-color: rgb(var(--clr-neutral-900));
    align-items: center;
    justify-content: space-between;
  }
  .row {
    display: flex;
    flex-direction: row;
    padding: 1rem 1.25rem;
    line-height: 2rem;
    margin: 0;
    border-radius: 0;
    align-items: center;
    justify-content: space-between;
  }
  .idColumn {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .shortColumn {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .longColumn {
    width: 16%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .quantityColumn {
    width: 16%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .groupColumn {
    width: 6%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .actionsColumn {
    cursor: pointer;
    width: 5%;
    display: flex;
    gap: 0.75rem;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: baseline;
  }
  .actionsColumn div {
    display: flex;
  }
  .actionsColumn a {
    display: flex;
  }