#manager-form{
  width: 100%;
  max-width: 750px;
  gap: 1rem;
  margin: 2rem auto;
  padding: 0rem 2rem;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}