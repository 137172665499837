.managementRoot {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  background-color: rgb(var(--clr-neutral-50));
}

@media screen and (min-width: 600px) {
  .managementContainer {
    margin-top: 0 !important;
    height: 100vh !important;
  }
}

.managementContainer {
  width: 100%;
  height: calc(100vh - 32px);
  overflow-y: auto;
  margin-top: 32px;
}

.managementContent {
  width: 100%;
  max-width: 1200px;
  gap: 1rem;
  margin: 0 auto;

  padding: 2rem 0.5rem;
}
