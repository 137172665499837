.container {
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  flex-grow: 1;
}
.container[data-grow='true'] {
  width: 100%;
}
.inputOutline {
  background-color: white;
  width: 100%;
  overflow: hidden;
  border: 1px solid rgb(var(--clr-neutral-900));
  border-radius: var(--rounded);
  padding: 0.25rem;
}
.inputOutline[data-onerror='true'] {
  border: 1px solid rgba(var(--clr-error, 1)) !important;
}
.inputOutline[data-disabled='true'] {
  border-color: rgba(var(--clr-neutral-900), 0.8);
  background-color: rgba(var(--clr-neutral-900), 0.05);
}
.container:hover .inputOutline:not([data-disabled='true']) {
  border-color: rgb(var(--clr-accent));
}
.input {
  color: rgb(var(--clr-neutral-900));
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  line-height: 1.5rem;
  width: 100%;
  margin-left: 0.5rem;
  outline: none;
  width: calc(100% - 0.75rem);
}
.input:disabled {
  color: rgba(var(--clr-neutral-900), 0.7);
}
