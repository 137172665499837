.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.body {
  width: 100%;
  max-width: 500px;
  padding: 3rem;
  color: rgb(var(--clr-neutral-900));
  background-color: rgb(var(--clr-neutral-50));
  overflow-y: auto;
  max-height: 100vh;
}
.body>div {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
}
.body form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:  0;
  gap: var(--spacing-md);
}

.background {
  width: 100%;
}
.background img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.changeClientLink {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.changeClientLink:hover {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .body {
    min-width: 100%;
    padding: 3rem 1.5rem;
  }
  .background {
    visibility: hidden;
  }
  .body form {
    margin: var(--spacing-xl) 0;
    gap: var(--spacing-xl);
  }
}
