.header {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.textInput {
  margin-top: 25px;
}

.saveButton {
  margin-top: 2rem;
  float: right;
}