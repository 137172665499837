.container {
  height: 3.5rem;
  width: 100%;
  background-color: rgb(var(--clr-neutral-900));
  color: rgb(var(--clr-neutral-50));
  display: flex;
  align-items: center;
}
.body {
  width: 100%;
  max-width: var(--body-width);
  padding: 0 2rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 2rem;
}

.desktopContainer{
  display: inherit;
}

.menuButton {
  display: flex;
  flex-direction: row;
  gap: var(--space-sm);
  align-items: center;
  padding: 0.25rem 0.5rem;
  border: 1px solid transparent;
  border-radius: var(--rounded);
  user-select: none;
}
.menuButton> svg {
  width: 22px;
  height: 22px;
}
.menuButton:hover {
  border-color: rgb(var(--clr-neutral-50));
}
.menuItem {
  width: 100%;
  padding: 0.25rem 1.5rem;
  line-height: 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}
.menuItem:hover {
  background-color: rgba(var(--clr-neutral-900), var(--opacity-md));
  color: white;
}

.profileImage {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.username {
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menus{
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}
@media screen and (max-width: 1200px){
  .desktopContainer {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .menuButton span {
    display: none;
  }
}