.chartContainer h2 {
  height: 2rem;
}
.chartContainer {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 2;
  padding: 1rem;
height: 400px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .chartContainer {
    grid-column: span 4;
    height: 440px !important;
  }
}
@media screen and (max-width: 767px) {
  .chartContainer {
    grid-column: span 2;
    height: 440px !important;
  }
}
