.container {
    display: flex;
    flex-direction: column;
    min-width: 10rem;
  }
.label {
    line-height: 2.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
.selectOutline {
    background-color: white;
    overflow: hidden;
    width: 100%;
    border: 1px solid rgb(var(--clr-neutral-900));
    border-radius: var(--rounded);
    padding: 0.25rem;
  }
.select {
    color: rgb(var(--clr-neutral-900));
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    line-height: 1.5rem;
    width: 100%;
  }