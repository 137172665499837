.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.indicator::after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: rgb(var(--clr-accent));
}

.label {
  padding-left: 20px;
  user-select: none;
  white-space: nowrap;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.radio {
  border: none;
  background-color: #fff;
  border-radius: 100%;
  width: 25px;
  min-width: 25px;

  height: 25px;
  border-radius: 100%;
  box-shadow: var(--shadow);
}

.radio:hover {
  background-color: rgb(var(--clr-accent));
}

.radio:focus {
  box-shadow: var(--shadow);
}

.radioItem {
  display: flex;
  align-items: center;
}

.overflowInherit {
  overflow: inherit;
}