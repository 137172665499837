.header {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.addButton {
  margin-top: 25px;
}

.list {
  background-color: #fff;
  border-radius: var(--rounded);
  padding: 0 0 0.625rem 0;
  margin-top: 1rem;
  border-color: #eee;
  border-width: 1px;
  overflow: hidden;
  box-sizing: border-box;
}

.listHeader {
  display: none;
}

.row {
  text-align: left;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 1rem 1.25rem;
  line-height: 2rem;
}

.row:not(:last-child) {
  border-bottom: 1px solid rgb(var(--clr-neutral-900));
}

@media screen and (min-width: 600px) {
  .listHeader {
    text-align: left;
    display: flex;
    flex-direction: row;
    padding: var(--space-sm) 1.25rem;
    color: rgb(var(--clr-neutral-50));
    background-color: rgb(var(--clr-neutral-900));
  }

  .row {
    text-align: left;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: var(--space-sm) 1.25rem;
    line-height: 2rem;
  }

  .row:hover {
    background-color: rgb(var(--clr-accent));
    color: rgb(var(--clr-neutral-50));
  }
}

.column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.missingText {
  background-color: #d8e55b;
}