.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.body {
  width: 100%;
  max-width: 500px;
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(var(--clr-neutral-900));
  background-color: rgb(var(--clr-neutral-50));
}
.body img {
  width: 220px;
  margin-bottom: 3rem;
}
.body form {
  margin: var(--spacing-xl) 0;
  display: flex;
  width: 100%;
  padding: 0 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl);
}

.background {
  width: 100%;
}
.background img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .body {
    min-width: 100%;
  }
  .background {
    visibility: hidden;
  }
}
