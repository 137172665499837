.container h2 {
  height: 2rem;
}

.container {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 1;
  padding: 1rem;
height: 400px;
  border-radius: var(--rounded);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}
.container *[data-success]{
  color: rgba(var(--clr-success));
}

.container *[data-error]{
  color: rgba(var(--clr-error))
}
.dataContainer {
  height: 100%;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(var(--clr-neutral-900));
}
.dataContainer table{
  border-collapse: collapse;
  width: 100%;
  border: none;
  white-space: nowrap;
}
.dataContainer td{
  border: none;
  text-align: start;
}
.dataContainer th{
  border: none;
  letter-spacing: 0.08rem;
  text-align: end;
}
.dataContainer tr{
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  padding-bottom: 0.5rem;
}

.leftColumn{
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

@media screen and (max-width: 1200px) {
  .container {
    grid-column: span 2;
    grid-row: 1;
    height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    grid-column: span 2;
    grid-row: 2;
  height: 400px;
  }
}
