.menu {
  position: absolute;
  top: calc(-1 * (100% - 32px));
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  width: 100%;
  min-width: 200px;
  background-color: rgb(var(--clr-neutral-900));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 2rem 0.5rem;
  color: rgb(var(--clr-neutral-50));
}
.menuMobileVisible {
  top: 0 !important;
}
.menuMobileButton {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  width: 100%;
  height: 42px;
  z-index: 100000;
  bottom: 0;
  background-color: rgb(var(--clr-neutral-900));
  border-radius: 0 var(--rounded) 0 0;
}
@media screen and (min-width: 1280px) {
  .menu {
    position: relative;
    max-width: 14rem;
    top: 0;
    padding: 1rem;
  }
  .menuMobileButton {
    display: none !important;
  }
}
