.chartsArea {
  position: relative;
  overflow: auto;
  margin-top: 1rem;
  height: 100%;
}

.container {
  width: 100%;
  height: calc(100vh - 3.5rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.equipmentArea {
  scroll-snap-align: start;
}

.tableContainer {
  width: 100%;
  overflow: auto;
  font-size: clamp(12px, 1vw, 18px);
  user-select: none;
}

.tableContainer table {
  border-collapse: collapse;
}

.tableContainer th {
  text-align: start;
  padding: 0.25em 0.5em;
  position: sticky;
  left: 0;
  background-color: rgb(var(--clr-neutral-50));
}

.tableContainer td {
  text-align: end;
  padding: 0.25em 0.5em;
}

.dataInvalid {
  background-color: rgba(var(--clr-error), 0.3);
}

.dataOk {
  background-color: rgba(var(--clr-blue), 0.3);
}

.dataRejected {
  background-color: #ec5 !important;
}