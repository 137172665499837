.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.container[data-align-left='true'] {
  align-items: flex-start;
}
.container[data-color-inverted='true'] {
  color: white;
}
.container[data-inline='true']{
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 1rem;
}
.checkbox {
  width: 34px;
  height: 34px;
  color: rgb(var(--clr-neutral-900));
  background-color: white;
  border: 1px solid rgb(var(--clr-neutral-900));
  border-radius: var(--rounded);
}
.checkbox[data-tiny='true']{
  width: 24px ;
  height: 24px;
}
.container:not([data-disabled='true']):hover {
  cursor: pointer;
}
.container:not([data-disabled='true']):hover .checkbox {
  border-color: rgb(var(--clr-accent));
}
.checkbox[data-disabled='true'] {
  border-color: rgba(var(--clr-neutral-900), 0.8);
  background-color: rgba(var(--clr-neutral-900), 0.05);
}

.checkbox svg {
  width: 100%;
  height: 100%;
}
