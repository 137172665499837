.tableContainer {
  width: 100%;
  overflow: auto;
  padding-top: 0.5rem;
  flex: 0 0 auto;
  font-size: clamp(12px, 1vw, 18px);
}

.tableContainer table {
  border-collapse: collapse;
}

.tableContainer th {
  text-align: start;
  padding: 0.25em 0.5em;
  border-right: 2px solid rgba(var(--clr-neutra-900));
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: rgba(var(--clr-neutral-50));
}

.tableContainer td {
  white-space: nowrap;
  text-align: end;
  padding: 0.25em 0.5em;
}

.chartsArea {
  position: relative;
  display: flex;
  flex-direction: column;
}

.classificationTitle {
  text-align: center !important;
  font-weight: bold;
}

.chartContent {
  flex: 1 1 auto;
  overflow: hidden;
}