.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.container aside {
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  height: calc(100vh - 120px);
}
.badFilterMessage{
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badfilterMessageContent{
  padding: 2rem;
  border-radius: var(--rounded);
  color: rgb(var(--clr-warning));
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  gap: 1rem;
  box-shadow: var(--shadow);
}
.chartDetailsArea{
  user-select: none;
  border-radius: var(--rounded);
  box-shadow: var(--shadow);
  padding: 0.5rem;
  background-color: white;
}

.title{
  position: relative;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: baseline;
}
.title svg {
  cursor: pointer;
}


.chart {
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.modalContainer {
  padding: 1rem;
  width: 100%;
  overflow: hidden;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.modalSampleList {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  width: 100%;
  max-width: 600px;
  overflow: auto;
  gap: 0.625rem;
}
.modalSampleItem {
  padding: 0 0.5rem;
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
}
.modalSampleItem div {
  white-space: nowrap;
}
.filterWarning {
  color: rgb(var(--clr-warning));
  text-align: center;
}
.averageCriteriumReport,
.individualCriteriumReport,
.criteriumReport {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.samplesList{
  max-height: 160px;
  overflow: auto;
}
.sampleItem{
  cursor: pointer;
  border-radius: var(--rounded-sm);
  padding: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.sampleItem[data-selected=true]{
  background-color: rgba(var(--clr-neutral-900), 0.1);

}
.sampleItem:hover{
  background-color: rgba(var(--clr-neutral-900), 0.2);
}
.body{
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
  }
  .body {
    flex-direction: column-reverse !important;
  }
}
