.container {
  width: 100%;
  overflow: auto;
}
.body{
  width: 100%;
  height: calc(100vh - 3.5rem);
  max-width: var(--body-width);
  padding: 0.5rem 2rem;
  margin: 0 auto;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.baseInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 100%;
}
.profilePicArea {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-right: 1.5rem;
}
.pic input {
  visibility: hidden;
}
.roleBadge {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  bottom: 0px;
  width: 100%;
}
.roleBadge div {
  max-width: min-content;
  padding: 0.125rem 0.25rem;
  background-color: rgb(var(--clr-neutral-50));
  color: rgb(var(--clr-neutral-900));
  border-radius: var(--rounded);
  border: 1px solid rgb(var(--clr-neutral-900));
  user-select: none;
}
.pic img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid rgb(var(--clr-neutral-900));
  cursor: pointer;
}
.pic svg {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid rgb(var(--clr-neutral-900));
  cursor: pointer;
}

.passwordData {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}
.passwordData>div{
  display: flex;
  flex-direction: row;
  gap: 1.75rem;
}
.passwordData>div[data-row]{
  grid-row: 2;
}

.controlButtons {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: flex-end;
}

.newPasswordData{
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px){
  .newPasswordData{
    flex-direction: column !important;
  }
  .controlButtons{
    margin-top: 2rem;
    flex-direction: column !important;
  }
}
@media screen and (max-width: 1600px){
  .container{
    height: calc(100vh - 3rem);
  }
}