.container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem 0;
  gap: 1rem;
}
.container h1 {
  width: 100%;
  text-align: center;
  color: rgb(var(--clr-neutral-900));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 1rem 0 1.5rem 0;
}
.buttons {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .buttons {
    flex-direction: column;
  }
}
