

.table {
  background-color: transparent;
  border-radius: var(--rounded);
  padding: 0.5rem;
  margin: 0.5rem 0;
  margin-top: 1rem;
  border-collapse: collapse;
  width: 100%;
  padding-top: 0.5em;
  flex: 0 0 auto;
  max-height: 200px;
  font-size: clamp(12px, 1vw, 18px);
}

.tableSection {
  min-height: 44vh;
  max-height: 44vh;
  overflow-y: auto;
  margin-bottom: 1rem;
}

table,
th,
td {
  border: 1px solid rgba(var(--clr-neutral-900), 0.1);
}
.table td {
  padding: 0.25em 0.5em;
}
.table th {
  text-align: start;
  padding: 0.25em 0.5em;
  font-weight: 500;
}
.table tr {
  line-height: 1.25em;
}


.chartsArea{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chartContent{
  min-height: 600px;
  flex: 1 1 auto;
  overflow: auto;
}