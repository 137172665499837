

.chartsArea{
  position: relative;
  overflow: auto;
  height: 100%;
}
.container{
  width: 100%;
  height: calc(100vh - 3.5rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  padding: 2rem 1.5em 1rem 1.5em;
  flex: 0 1;
}
.equipmentArea{

  scroll-snap-align: start;
}
@media screen and (max-width: 1600px){
  .container{
    height: calc(100vh - 3rem);
  }
}
