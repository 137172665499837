.container{
  max-width: 1200px;
  padding: 1rem;
  margin:auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}


.data{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 100%;
  align-items: flex-end;
}