.container{
  padding: 1.5rem;
  max-height: 80vh;
  overflow: hidden;
  position: relative;
  width: 80vw;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bulkSelection{
  display: flex;
  gap: 1.5rem;
}

.list{
  width:100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 60vh;
  padding: 1rem 0;
  gap: 0.75rem;
  overflow-x: scroll;
  margin-bottom: 0.5rem;
}
.actions{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
}

@media screen and (max-width: 767px){
  .header {
    flex-direction: column;
    gap: 1rem;
  }
  .container{
    padding: 1.5rem 0.5rem;
    width: 100%;
  }
}
