.header {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.languageForm {
  width: 100%;
  gap: 1rem;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 0.125rem;
  display: flex;
  flex-direction: column;
  color: rgb(var(--clr-neutral-900));
}

@media screen and (min-width: 600px) {
  .languageForm {
    padding: 0 2rem !important;
  }
}

.saveButton {
  margin-top: 1rem;
  align-self: flex-end;
}