.container {
  z-index: 100;
  background-color: rgba(var(--clr-neutral-900));
  position: absolute;
  width: 100%;
}
.body{
  background-color: #ffffff10;
}
.body main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  gap: 1rem;
  width: 100%;
  max-width: var(--body-width);
  margin: auto;
  max-height: 0;
  transition: max-height ease-in-out 0.3s;
}
.body main[data-open='true'] {
  max-height: 770px;
  padding-top: 0.5rem;
}
.body footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  color: white;
  padding: 0.25rem 0;
  cursor: pointer;
}
.body footer svg {
  transform: scale(2, 1);
}
.filterPeriod {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
}
.datetimeContainer{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
@media screen and (min-width: 767px) {
  .body main{
    flex-direction: row;
    gap: 2rem;
  }
  .body main[data-open='true']{
    max-height: 350px;
  }
  .filterPeriod {
    flex-direction: row !important;
    margin-left: 1rem;
  }
}
