.header {
  display: flex;
gap: 2rem;
align-items: center;
}
.invoice {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: bold;
}
.invoice a {
  cursor: pointer;

}
.subscriptionPrice {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

.dateArea {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  flex-grow: 0;
}

.billingCycle {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}