.container {
  display: flex;
  flex-direction: column;
  min-width: 10rem;
}
.container[data-grow='true'] {
  width: 100%;
}
.container:hover .selectOutline:not([data-disabled='true']) {
  border-color: rgb(var(--clr-accent));
}
.containerGrow {
  width: 100%;
}

.selectOutline {
  background-color: white;
  overflow: hidden;
  width: 100%;
  border: 1px solid rgb(var(--clr-neutral-900));
  border-radius: var(--rounded);
  padding: 0.25rem;
}
.selectOutline[data-disabled='true'] {
  border-color: rgba(var(--clr-neutral-900), 0.8);
  background-color: rgba(var(--clr-neutral-900), 0.05);
}
.select {
  color: rgb(var(--clr-neutral-900));
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  line-height: 1.5rem;
  width: 100%;
}
