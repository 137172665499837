.container {
  width: 100%;
  padding: 2rem 1.5em 1rem 1.5em;
  height: calc(100vh - 3.5rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.chartsArea{
  position: relative;
  margin-top:1rem;
  overflow: auto;
  height: 100%;
}
.title{
  position: relative;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: baseline;
}
.title svg{
  cursor: pointer;
}
.title>svg {
  stroke: rgba(var(--clr-accent));
  fill: rgba(var(--clr-accent));
}
.title>svg:hover{
  fill: rgba(var(--clr-accent), 0.6);
}

@media screen and (max-width: 1600px){
  .container{
    height: calc(100vh - 3rem);
  }
}