.usersList {
  border-radius: var(--rounded);
  padding: 0 0 0.625rem 0;
  margin-top: 1rem;
  border-color: #eee;
  border-width: 1px;
  overflow: hidden;
  box-sizing: border-box;
}
.header {
  display: none;
}
.row {
  background-color: #fff;
  border-radius: var(--rounded);
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  line-height: 2rem;
  margin: 0.5rem 0;
}

.column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.container {
  padding: 1rem 0.5rem;
}
.actionsColumn {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  gap: 0.5rem;
}
.actionsColumn>div{
  display: flex;
}
.actionsColumn svg {
  cursor: pointer;
  width: 22px;
  height: 22px;
}
.refreshIcon {
  position: absolute;
  right: -4px;
  background-color: #fff;
  border-radius: 50%;
  bottom: 4px;
  width: 16px !important;
  height: 16px !important;
}

.resetPasswordIcon {
  position: relative;
  align-items: center;
}
.column {
  width: 100%;
}
.nameColumn {
  padding-left: 3rem;
}
.statusColumn {
  position: absolute;
}
.statusColumn svg {
  width: 28px;
  height: 28px;
}
.statusColumn:hover svg {
  opacity: var(--opacity-no) !important;
}
.blocked {
  color: var(--clr-error);
}
.unblocked {
  color: var(--clr-success);
}
.tenantOwner {
  color: var(--clr-warning);
}
.actionsColumn {
  position: absolute;
  right: 2rem;
}
.userForm {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5rem;
}
.userForm h1 {
  width: 100%;
  text-align: center;
  color: rgb(var(--clr-neutral-900));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 1rem 0 1.5rem 0;
}
.userForm button {
  margin: 1.5rem 0 0.5rem 0;
}
.controlButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2rem;
}

@media screen and (min-width: 600px) {
  .userForm {
    padding: 1rem 10rem;
  }
  .userForm h1 {
    margin: 2rem 0 4rem 0 !important;
  }
  .userForm button {
    margin: 3rem 0 2rem 0;
  }
  .usersList {
    background-color: #fff;
  }
  .row:not(:last-child) {
    border-bottom: 1px solid rgb(var(--clr-neutral-900));
  }
  .container {
    padding: 1rem 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  .header {
    padding: var(--space-sm) 1.25rem;
    text-align: left;
    display: flex;
    color: rgb(var(--clr-neutral-50));
    background-color: rgb(var(--clr-neutral-900));
  }
  .row {
    text-align: left;
    display: flex;
    flex-direction: row;
    padding: 1rem 1.25rem;
    line-height: 2rem;
    margin: 0;
    border-radius: 0;
  }
  .row svg:hover {
    opacity: var(--opacity-high);
  }

  .resetPasswordIcon:hover {
    opacity: var(--opacity-high);
  }
  .refreshIcon:hover {
    opacity: var(--opacity-no) !important;
  }

  .statusColumn {
    position: inherit;
    width: 10%;
  }
  .nameColumn {
    padding-left: 0rem;
    width: 30%;
  }
  .emailColumn {
    width: 30%;
  }
  .roleColumn {
    width: 20%;
  }
  .actionsColumn {
    width: 10%;
  }
}
