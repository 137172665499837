.button {
  border-radius: var(--rounded);
  background-color: rgb(var(--clr-accent));
  margin: 0;
  color: rgb(var(--clr-neutral-50));
  font-weight: 600;
  border: none;
  line-height: 1.75rem;
  padding: 0.25rem 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 50ms 0s ease-in;
  max-height: min-content;
}


.button[data-textual='true']{
  background-color: transparent;
  color: rgb(var(--clr-accent));
  align-items: baseline;
  padding: 0 0.5rem;
}
.button[data-textual='true']:hover{
  text-decoration: underline;
}

.button[data-disabled=true]{
  cursor: default;
  background-color: grey !important;
}

.button[data-disabled=true]:hover{
  opacity: 100;
}

.button.danger {
  background-color: rgb(var(--clr-error));
}
.button[data-textual='true'].danger{
  color: rgb(var(--clr-error));
  background-color: transparent;
}

.button.secondary {
  color: rgb(var(--clr-accent));
  background-color: transparent;
  border: 2px solid rgb(var(--clr-accent));
}

.button.secondary.danger {
  border-color: rgb(var(--clr-error));
  color: rgb(var(--clr-error));
}

.button svg {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.25rem;
}
.button:hover {
  opacity: var(--opacity-low);
}
