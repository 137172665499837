.container {
    margin: 0 auto;
    padding: 2rem 1.5rem;
    height: calc(100vh - 4rem);
    padding: 2rem 1.5em 1rem 1.5em;

    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: left;
  }
.cardsSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem;
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.card {
    width: 18rem;
    height: 26rem;
    padding: 0.25rem 0.625rem;
    border-radius: var(--rounded);
    box-shadow: var(--shadow);
    background: var(--clr-neutral-900);
}
.card > *:not(:last-child) {
  margin-bottom: 0.5rem;
}
.card > *:last-child {
  margin-bottom: -1.25rem;
}
.product {
  font-size: 0.9rem;
}
.product > * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.productionData > *{
  margin-bottom: 0.425rem;
}
.metrics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.825rem;
}
.noData {
  font-size: 0.725rem;
  margin-top: 1rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.greenText {
  color: #21dc92;
}
.redText {
  color: #ec5757;
}